import React from 'react';
import styled from 'styled-components';

const Host = styled.div``;

interface StickyProps {
  children: React.ReactNode;
}

export const Sticky = ({ children }: StickyProps) => {
  return <Host>{children}</Host>;
};
