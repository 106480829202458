import { Container } from '@mantine/core';
import { motion } from 'framer-motion';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { device } from '../../styles/devices';
import { linkColor } from '../../styles/mixins';
import { bodyXs, hero } from '../../styles/typography';

const Host = styled.div`
  position: relative;

  display: flex;

  align-items: center;

  min-height: 100vh;
  overflow: hidden;

  padding: 90px 0;

  border-bottom: 1px solid rgba(0, 0, 1, 0.1);
  border-color: ${({ theme }) =>
    theme.colorScheme === 'dark'
      ? 'rgba(255,255,255,0.2)'
      : 'rgba(0, 0, 1, 0.1)'};
`;

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  margin-bottom: 30px;

  @media ${device.mobile} {
    flex: 1;
    margin-bottom: 84px;
  }
`;

const Title = styled.h1`
  margin: 0;
  ${hero}
  margin-bottom: 62px;

  @media ${device.mobile} {
    max-width: 60%;
    margin-bottom: 82px;
  }

  strong {
    font-weight: 600;
  }
`;

const Actions = styled.div`
  margin-bottom: 10px;
  @media ${device.mobile} {
    margin-bottom: ${(props) => props.theme.gutter}px;
  }
`;

const SubText = styled.p`
  ${bodyXs}
  margin: 0;
  font-size: 14px;
  align-self: flex-end;
  @media ${device.mobile} {
    max-width: 33%;
    margin-top: -10px;
  }

  a {
    ${linkColor}
  }
`;

const Wrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

const RunnerLine = styled.span`
  position: absolute;
  left: 50%;
  top: 130px;

  height: 500px;

  width: 1px;
  background-color: ${({ theme }) =>
    theme.colorScheme === 'dark'
      ? 'rgba(255,255,255,0.2)'
      : 'rgba(0, 0, 1, 0.1)'};

  @media ${device.mobile} {
    top: 0;
  }
`;

const Bounce = keyframes`
50% {
      transform: translateY(-30%);
    }
`;

const Runner = styled(motion.span)`
  position: absolute;
  left: calc(50% - 3px);
  top: 130px;

  animation: ${Bounce} 1s ease-in-out 3;

  height: 43px;
  width: 7px;
  background-color: ${({ theme }) =>
    theme.colorScheme === 'dark'
      ? 'rgba(255,255,255,0.8)'
      : 'rgba(0, 0, 1, 1)'};

  @media ${device.mobile} {
    top: 0;
  }
`;

const Rotate = styled.span`
  display: inline-flex;
  overflow: hidden;
  position: relative;
  font-weight: 600;
`;

const Hidden = styled.span`
  opacity: 0;
`;

const El = styled(motion.span)`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
`;

const Roller = styled(motion.div)`
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 0;
`;

const Item = styled(motion.div)``;

interface HeroProps {
  actions: React.ReactNode;
  sub?: React.ReactNode;
}

export const Hero = ({ actions, sub }: HeroProps) => {
  return (
    <Host>
      <Container size="xl" p="xl" sx={{ width: '100%' }}>
        <Content>
          <Title>
            <>
              Úr <strong>ISK</strong> í{' '}
              <Rotate>
                <Hidden>SUSHI</Hidden>
                <Roller
                  animate={{
                    y: [
                      '10%',
                      '0%',
                      '-10%',
                      '-20%',
                      '-30%',
                      '-40%',
                      '-50%',
                      '-60%',
                      '-70%',
                      '-80%',
                      '-90%',
                      '-100%',
                    ],
                  }}
                  transition={{
                    duration: 10,
                    ease: 'easeInOut',
                    times: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                    repeat: Infinity,
                  }}>
                  <Item>BTC</Item>
                  <Item>ETH</Item>
                  <Item>SOL</Item>
                  <Item>USDC</Item>
                  <Item>LINK</Item>
                  <Item>BNB</Item>
                  <Item>UNI</Item>
                  <Item>SUSHI</Item>
                  <Item>SRM</Item>
                  <Item>RAY</Item>
                </Roller>
              </Rotate>
              <br />
              og aftur heim á allt öðrum hraða
            </>
          </Title>
          <Actions>{actions}</Actions>
        </Content>

        <Wrap>
          <Runner />
          <RunnerLine />
          <SubText>{sub}</SubText>
        </Wrap>
      </Container>
    </Host>
  );
};
