import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';
import { h4 } from '../../styles/typography';

const Host = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 50px 0;

  @media ${device.mobile} {
    padding: 90px 0;
  }

  @media ${device.tablet} {
    flex-flow: row nowrap;
  }
`;

const Left = styled.div<{ isLastItem?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;

  margin-bottom: 30px;

  @media ${device.mobile} {
    margin-bottom: 60px;
  }

  @media ${device.tablet} {
    padding-top: 90px;
    margin-bottom: 0;
  }

  &:after {
    @media ${device.tablet} {
      content: '';
      display: ${(props) => (props.isLastItem ? 'none' : 'inline-block')};
      top: 50%;

      position: absolute;
      left: 23%;

      height: 75%;
      width: 2px;

      background: linear-gradient(
        to bottom,
        transparent 50%,
        rgba(0, 0, 0, 0.3) 50%
      );
      background-size: 1px 12px, 100% 1px;
    }

    @media ${device.desktop} {
      top: 70%;
    }
  }
`;

const Image = styled.div`
  @media ${device.tablet} {
    max-width: 260px;
  }

  @media ${device.desktop} {
    max-width: 450px;
  }
`;

const Right = styled.div`
  flex: 1;
`;

const Title = styled.h2`
  ${h4};
  font-weight: 600;
`;

const Copy = styled.div`
  margin-bottom: 44px;
`;

const Action = styled.div``;

interface StickyItemProps {
  image: React.ReactNode;
  title: string;
  copy: React.ReactNode;
  action?: React.ReactNode;
  isLastItem?: boolean;
}

export const StickyItem = ({
  image,
  title,
  copy,
  action,
  isLastItem,
}: StickyItemProps) => {
  return (
    <Host>
      <Left isLastItem={isLastItem}>
        <Image>{image}</Image>
      </Left>
      <Right>
        <Title>{title}</Title>
        <Copy>{copy}</Copy>
        {action && <Action>{action}</Action>}
      </Right>
    </Host>
  );
};
