import {
  Accordion,
  Box,
  Button,
  Image,
  Modal,
  Text,
  Title,
} from '@mantine/core';
import Link from 'next/link';
import Script from 'next/script';
import { useState } from 'react';
import { AccordionContent } from '../components/accordion/accordion-content';
import { AccordionLabel } from '../components/accordion/accordion-label';
import { BackgroundAnimation } from '../components/background/background-animation';
import { ActionButton } from '../components/button/action-button';
import { HeroButton } from '../components/button/hero-button';
import { Card } from '../components/card/card';
import { CardGroup } from '../components/card/card-group';
import { CardLink } from '../components/card/card-link';
import { StepCard } from '../components/card/step-card';
import { Divider } from '../components/divider/divider';
import { FeatureItem } from '../components/features/feature-item';
import { Features } from '../components/features/features';
import { Hero } from '../components/hero/hero';
import { Segment } from '../components/segment/segment';
import { Signup } from '../components/signup/signup';
import { Sticky } from '../components/sticky/sticky';
import { StickyItem } from '../components/sticky/sticky-item';
import { Video } from '../components/video/video';
import { MetaContainer } from '../containers/meta/meta-container';
import { useAuthStore } from '../lib/auth-store';

export default function Home() {
  const auth = useAuthStore();

  const [beta, setBeta] = useState(false);
  return (
    <>
      <MetaContainer title="Forsíða" />
      <Script
        src="https://forms-widget.getgist.com/"
        strategy="afterInteractive"
      />
      <Modal
        withCloseButton={false}
        opened={true}
        onClose={() => setBeta(false)}
        hidden={!beta}>
        <Box sx={{ padding: 20, marginBottom: -16 }}>
          <Title order={4} sx={{ marginBottom: 16 }}>
            Viltu vera með þeim fyrstu til að prófa rafkrónur?
          </Title>
          <Text>
            Skráðu þig hér og við látum þig vita þegar þú kemst að til að prófa.
          </Text>
        </Box>
        <div
          className="gist-embed-form"
          data-form-type="embedded"
          data-form-id="82319697"
          data-workspace-id="gzvsg4gx"
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setBeta(false)} variant="light" color="gray">
            Loka
          </Button>
        </div>
      </Modal>
      <BackgroundAnimation>
        <Hero
          actions={
            <>
              {auth.loggedIn() ? (
                <Link href="/dashboard" passHref>
                  <HeroButton>Mitt svæði</HeroButton>
                </Link>
              ) : (
                <Link href="/login" passHref>
                  <HeroButton>Opna reikning</HeroButton>
                </Link>
              )}
            </>
          }
          sub={
            <>
              Mintum er í eigu Rafmyntasjóðs Íslands sem hefur hlotið{' '}
              <a
                href="https://www.sedlabanki.is/utgefid-efni/frettir-og-tilkynningar/frettasafn/frett/2022/07/08/Rafmyntasjodur-Islands-ehf.-skrad-sem-thjonustuveitandi-syndareigna/"
                target="_bland"
                rel="norefferer nofollow">
                skráningu
              </a>{' '}
              sem þjónustuveitandi sýndarfjár og lýtur eftirliti Seðlabankans
              skv. lögum um aðgerðir gegn peningaþvætti og fjármögnun
              hryðjuverka.
            </>
          }
        />

        <Segment
          title="Kostir Mintum"
          description="Við leggjum allt uppúr því að vera með hraðvirka þjónustu, lægri þóknanir og aukið öryggi.">
          <Features>
            <FeatureItem
              image={
                <Image src="/illustrations/speed.png" alt="Hraði á klukku" />
              }
              title="Styttri biðtími"
              copy="Færslur taka sekúndur, ekki daga. Bið eftir að erlendar bankamillifærslur skili sér heyrir sögunni til."
              isGrayBg={false}
            />
            <FeatureItem
              image={<Image src="/illustrations/save.png" alt="Spari grís" />}
              title="Hverfandi kostnaður"
              copy="Mintum er besta leiðin inn og út úr rafmyntaheiminum. Engin þóknun við skipti og aðeins 195 kr. úttektargjald."
              isGrayBg={false}
            />
            <FeatureItem
              image={
                <Image
                  src="/illustrations/security.png"
                  alt="Öryggis skjöldur"
                />
              }
              title="Öryggi í fyrirrúmi"
              copy="Öruggur aðgangur með rafrænum skilríkjum og fjölþátta auðkennisleiðir."
              isGrayBg={false}
            />
          </Features>
        </Segment>

        <Divider />

        {/* <Segment>
          <Market
            title="Viltu enn betri kjör?"
            copy="Aukið fjármagn í lausafjársjóðum bætir flæði og verð fyrir alla notendur ISKT. Þeir notendur sem leggja rafmyntir inn í ISKT lausafjársjóði fá þóknanir þegar aðrir notendur eru að skipta ISKT. Notendur sem leggja inn meira en 100.000 ISKT í lausafjársjóði greiða ekki skipti gjald hjá Mintum."
            action={
              <Link
                href="https://support.mintum.is/article/39-thattakendur-i-lausafjarsjodum-greida-0"
                passHref>
                <ActionButton>Lesa nánar</ActionButton>
              </Link>
            }
          />
        </Segment>

        <Divider /> */}

        <Segment>
          <Sticky>
            <StickyItem
              image={
                <Image src="/illustrations/iskt-logo.svg" alt="Iskt Logo" />
              }
              title="Mintum breytir krónum í rafkrónur"
              copy={
                <>
                  <p>
                    Rafkrónur (ISKT) er{' '}
                    <a
                      href="https://support.mintum.is/article/30-hvad-er-rafmynt-rafeyrir-og-syndarfe?"
                      target="_bland"
                      rel="norefferer nofollow">
                      sýndarfé
                    </a>{' '}
                    útgefið af Rafmyntasjóði Íslands. ISKT er fastgengismynt (e.
                    stablecoin), bundin við ISK á genginu 1:1. Fyrir hverja
                    rafkrónu (ISKT) í umferð er ein króna (ISK) í eignasafni
                    Rafmyntasjóðs Íslands.
                  </p>
                  <p>
                    Löggiltir endurskoðendur ganga úr skugga um og votta að
                    eignasafn Rafmyntasjóðs Íslands standi undir öllum rafkrónum
                    í umferð.
                  </p>
                  <p>
                    Með ISKT geta íslenskir notendur fært krónur yfir í sýndarfé
                    á svipstundu og með lágmarksþóknun.
                  </p>
                </>
              }
            />
            <StickyItem
              image={<Image src="/images/dexGroup.png" alt="dexGroup logo" />}
              title="Rafkrónan á markað"
              copy={
                <>
                  <p>
                    ISKT er tengd við lausafjársjóði (e. liquidity pools) og
                    Openbook kauphöllina. Einfalt swap viðmót á mintum.is sýnir
                    þér hagstæðustu leiðina hverju sinni og framkvæmir skiptin
                    með einum smelli.
                  </p>
                  <p>
                    Fjárfestar geta ávaxtað rafkrónurnar sínar með því að leggja
                    þær inn í lausafjársjóði og fá þess í stað þóknanir sem
                    notendur borga fyrir að skipta milli rafmynta.
                  </p>
                  <p>
                    Notendur rafkróna greiða mun lægri þóknanir en almennt
                    gerist hjá rafmyntamiðlurum og kreditkortafyrirtækjum.
                  </p>
                </>
              }
              action={
                <Link
                  href="https://support.mintum.is/article/34-hvernig-breyti-eg-rafkronum-i-adrar-rafmyntir"
                  passHref>
                  <ActionButton>Nánar um swap</ActionButton>
                </Link>
              }
            />
            <StickyItem
              image={
                <Image src="/illustrations/market2.png" alt="Coinbase logo" />
              }
              title="Fleiri valkostir"
              copy={
                <p>
                  Notendur sem vilja meira úrval geta fært rafmyntir samstundis
                  á reikninga sína hjá Coinbase, Binance, Kraken og öðrum
                  kauphöllum.
                </p>
              }
              isLastItem
              action={
                <Link
                  href="https://support.mintum.is/article/35-mintum-is-tengir-bankareikninginn-thinn-vid-erlendar-kauphallir"
                  passHref>
                  <ActionButton>Sjá dæmi</ActionButton>
                </Link>
              }
            />
          </Sticky>
        </Segment>

        <Divider />

        <Segment
          title="Breyttu krónu í Bitcoin"
          description="Það tekur minna en 5 mínutur að breyta fyrstu krónunni í Bitcoin.">
          <Features>
            <StepCard
              image={
                <Image src="/icon/fingrafar.svg" alt="Fingrafar" radius="lg" />
              }
              title="Þú stofnar notanda"
              copy="Nýskráning fer fram með rafrænum skilríkjum."
            />
            <StepCard
              image={
                <Image src="/icon/kort.svg" alt="Seðlabanki Logo" radius="lg" />
              }
              title="Þú millifærir"
              copy="Þú leggur íslenskar krónur inn á þinn reikning hjá Mintum."
            />
            <StepCard
              image={
                <Image
                  src="/icon/faersla.svg"
                  alt="Seðlabanki Logo"
                  radius="lg"
                />
              }
              title="Þú skiptir ISK í ISKT"
              copy="Með einum smelli breytir þú íslenskum krónum í rafkrónur."
            />
            <StepCard
              image={
                <Image src="/icon/markadur.svg" alt="Mintum swap" radius="lg" />
              }
              title="Auðvelt að skipta"
              copy="Þú notar rafkrónur til að skipta yfir í aðrar rafmyntir beint á mintum.is."
            />
          </Features>
        </Segment>

        <Divider />

        <Segment
          title="Einfaldari en heimabankinn"
          description="Það tekur sekúndur að breyta krónu í rafkrónu.">
          <Video
            iframe={
              <iframe
                width="100%"
                height="425"
                src="https://www.youtube.com/embed/mhHDn28BjuM"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            }>
            <Image src="/temp/mac.png" alt="video" radius="lg" />
          </Video>
        </Segment>

        <Divider />

        <Segment
          title="Nánari upplýsingar"
          description="Við eigum gott safn greina um kerfið okkar, Solana bálkakeðjuna, swappið og fleiri tengd málefni."
          action={
            <Link href="https://support.mintum.is/" passHref>
              <ActionButton
                target="_blank"
                rel="norefferer nofollow"
                isCentered>
                Fleiri greinar
              </ActionButton>
            </Link>
          }>
          <CardGroup>
            <Card
              image={<Image src="/images/News1@2x.jpg" alt="Mintum Kerfið" />}
              title="Kerfið"
              copy="Þú notar mintum.is til að skipta krónum yfir í rafkrónur. Við höfum tekið saman stuttar greinar um notkun kerfisins."
              action={
                <Link
                  href="https://support.mintum.is/article/20-mintum-is-kerfid"
                  passHref>
                  <CardLink target="_blank" rel="norefferer nofollow">
                    Lesa meira
                  </CardLink>
                </Link>
              }
            />
            <Card
              image={
                <Image src="/images/News2@2x.jpg" alt="Solana networkið" />
              }
              title="Solana"
              copy="Solana er dreifistýrð bálkakeðja. Meginmarkmið bálkakeðjunnar er að ná fram hraða og halda færslukostnaði í lágmarki."
              action={
                <Link
                  href="https://support.mintum.is/article/4-solana"
                  passHref>
                  <CardLink target="_blank" rel="norefferer nofollow">
                    Lesa meira
                  </CardLink>
                </Link>
              }
            />
            <Card
              image={
                <Image src="/images/News3@2x.jpg" alt="Swappið á mintum.is" />
              }
              title="Swappið"
              copy="Swappið okkar finnur fyrir þig besta verðið fyrirhafnarlaust. Þú geymir rafmyntirnar sjálfur í þínu veski."
              action={
                <Link
                  href="https://support.mintum.is/article/34-hvernig-breyti-eg-rafkronum-i-adrar-rafmyntir"
                  passHref>
                  <CardLink target="_blank" rel="norefferer nofollow">
                    Lesa meira
                  </CardLink>
                </Link>
              }
            />
          </CardGroup>
        </Segment>

        <Divider />

        <Segment
          title="Eins öruggt og hugsast getur"
          description="Allar innskráningar fara í gegnum rafræn skilríki Auðkennis. Auk þess geta notendur valið fjórar mismunandi fjölþátta auðkenningar, allt frá textaskilaboðum til öryggislykla."
          isReverse
          isSmallerTitle>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Image
              src="/illustrations/security.svg"
              alt="Öryggis"
              radius="sm"
              width={179}
            />
          </Box>
        </Segment>

        <Divider />

        <Segment
          title="Gagnsæi og áreiðanleiki"
          description={
            <>
              <p>
                Rafmyntasjóður Íslands hefur hlotið{' '}
                <a
                  href="https://www.sedlabanki.is/utgefid-efni/frettir-og-tilkynningar/frettasafn/frett/2022/07/08/Rafmyntasjodur-Islands-ehf.-skrad-sem-thjonustuveitandi-syndareigna/"
                  target="_bland"
                  rel="norefferer nofollow">
                  skráningu
                </a>{' '}
                sem þjónustuveitandi sýndarfjár og lútir eftirliti Seðlabanka
                Íslands skv. lögum um aðgerðir gegn peningaþvætti og fjármögnun
                hryðjuverka. Félagið leggur allt kapp á gagnsæi og áreiðanleika
                en löggiltir endurskoðendur{' '}
                <a
                  href="http://mintum.is/certificate/"
                  target="_bland"
                  rel="norefferer nofollow">
                  staðfesta
                </a>{' '}
                að innistæða bankareikninga standi undir öllum rafkrónum á
                markað hverju sinni.
              </p>
            </>
          }
          isSmallerTitle>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Image
              src="/logo/le_logo.png"
              alt="Löggiltir endurskoðendur"
              width="50"
            />
          </Box>{' '}
        </Segment>

        <Divider />

        <Segment
          title="Spurt og svarað"
          isSmallerTitle
          action={
            <Link href="https://support.mintum.is/" passHref>
              <ActionButton
                isCentered
                target="_blank"
                rel="norefferer nofollow"
                bgColor="#fefefe">
                Hjálpin
              </ActionButton>
            </Link>
          }>
          <Accordion defaultValue="1" chevronPosition="right">
            <Accordion.Item value="1">
              <AccordionLabel title="Hvað get ég gert með ISKT?" />
              <AccordionContent>
                ISKT er gjaldgeng í lausafjársjóðum og OpenBook kauphöllinni þar
                sem hægt er að kaupa hundruði rafmynta á einum stað. Einfaldast
                er að nota{' '}
                <a href="https://support.mintum.is/article/34-hvernig-breyti-eg-rafkronum-i-adrar-rafmyntir">
                  swappið okkar.
                </a>{' '}
                Með því að kaupa ISKT og flytja það sjálfur yfir í kauphöll
                getur þú sparað verulegar fjárhæðir sem öðrum kosti færu í
                þóknanir ýmist hjá íslenskum rafmyntamiðlurum eða
                kreditkortafyrirtækjum. Þú getur líka notað ISKT til að færa{' '}
                <a href="https://support.mintum.is/article/35-mintum-is-tengir-bankareikninginn-thinn-vid-erlendar-kauphallir">
                  fjármuni til og frá erlendum kauphöllum{' '}
                </a>{' '}
                s.s. Binance, Kraken, Coinbase o.s.frv.
              </AccordionContent>
            </Accordion.Item>
            <Accordion.Item value="2">
              <AccordionLabel title="Hvað með öryggi?" />
              <AccordionContent>
                Mintum setur öryggi viðskiptavina í fyrsta sæti. Allar
                innskráningar fara fram í gegnum örugga auðkennisleið Island.is.
                Til að tryggja aðganginn sinn frekar geta notendur valið að
                skilyrða innskráningu og notkun enn frekar með auðkenningarappi,
                dulkóðunarlykli, sms og/eða netfangi.
              </AccordionContent>
            </Accordion.Item>
            <Accordion.Item value="3">
              <AccordionLabel title="Hvað eru aðgerðir gegn peningaþvætti og fjármögnun hryðjuverka?" />
              <AccordionContent>
                Félagið er bundið af lögum nr. 140/2018 um aðgerðir gegn
                peningaþvætti og fjármögnun hryðjuverka. Félagið þjónustar
                viðskiptavini á grundvelli innra áhættumats, stefnu, verkferla,
                og stýringa en ábyrgðarmaður félagsins sinnir reglubundnu
                eftirliti með viðskiptum. Félagið er rannsóknar- og
                tilkynningarskylt ef grunur leikur um að viðskipti tengjast
                peningaþvætti og/eða fjármögnun hryðjuverka.
              </AccordionContent>
            </Accordion.Item>

            <Accordion.Item value="4">
              <AccordionLabel title="Hvað er fastgengismynt? (e. stablecoin)" />
              <AccordionContent>
                Fastgengismynt er rafmynt sem hefur verið tengd við gjaldeyri á
                genginu 1:1. Ef þú átt rafkrónur getur þú treyst því að geta
                alltaf skipt yfir í hefðbundnar krónur og tilbaka.
                Rafmyntasjóður geymir alltaf eina króna fyrir hverja rafkrónu í
                umferð.
              </AccordionContent>
            </Accordion.Item>
            <Accordion.Item value="5">
              <AccordionLabel title="Hvað kostar að breyta Íslenskum krónum í ISKT?" />
              <AccordionContent>
                Það kostar ekki neitt engin gjöld eru tekin við að breyta
                íslenskum krónum í ISKT.
              </AccordionContent>
            </Accordion.Item>
            <Accordion.Item value="6">
              <AccordionLabel title="Þarf ég að vera með íslenskur ríkisborgari?" />
              <AccordionContent>
                Kerfið er eins og er einungis fyrir íslenska ríkisborgara með
                íslenska kennitölu og rafræn skilríki.
              </AccordionContent>
            </Accordion.Item>
            <Accordion.Item value="7">
              <AccordionLabel title="Get ég tekið út krónur á þriðja aðila?" />
              <AccordionContent>
                Nei, einungis er hægt að taka út krónur á bankareikninga á þinni
                kennitölu.
              </AccordionContent>
            </Accordion.Item>
            <Accordion.Item value="8">
              <AccordionLabel title="Get ég keypt Bitcoin á síðunni?" />
              <AccordionContent>
                Nei ekki hægt að kaupa beint BTC á síðunni en hægt er að kaupa
                wrapped Bitcoin í swappinu okkar. Þú getur líka fært rafmyntir
                áfram í stærri kauphallir þar sem til að kaupa native BTC.
              </AccordionContent>
            </Accordion.Item>
            <Accordion.Item value="9">
              <AccordionLabel title="Þarf ég að vera með rafræn skilríki til að skrá mig inn?" />
              <AccordionContent>
                Já, þú þarft að vera með rafræn skilríki.
              </AccordionContent>
            </Accordion.Item>
            <Accordion.Item value="10">
              <AccordionLabel title="Hvað er token addressan fyrir ISKT?" />
              <AccordionContent>
                Token addressan fyrir iskt er
                isktkk27QaTpoRUhwwS5n9YUoYf8ydCuoTz5R2tFEKu. Hægt er að skoða
                tokenið á vefsvæði{' '}
                <a
                  href="https://solscan.io/token/isktkk27QaTpoRUhwwS5n9YUoYf8ydCuoTz5R2tFEKu"
                  target="_blank"
                  rel="noopener noreferrer">
                  solscan{' '}
                </a>
              </AccordionContent>
            </Accordion.Item>
          </Accordion>
        </Segment>
      </BackgroundAnimation>

      <Signup
        title="Stígðu inn í framtíðina"
        description={
          <>
            Það eina sem þarf til að stofna reikning er 3 mínútur og rafræn
            skilríki frá Auðkenni. Það kostar ekkert að stofna reikning.
          </>
        }
        image={
          <Image
            src="/illustrations/future.png"
            alt="Hús sem breytir krónum í rafkrónur"
            width={959}
          />
        }>
        <Link href="/login" passHref>
          <ActionButton isWhite>Opna reikning</ActionButton>
        </Link>
      </Signup>
    </>
  );
}
